import React, { useState, useEffect } from "react";
import projectDemo from "./projectDemoMov.mp4";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Gesture Based Volume Control Interface - OpenCV | Python"
          subtitle="Implemented an intuitive volume control system using hand gestures with Python and OpenCV. This innovative solution allows users to dynamically adjust volume levels on a device through intuitive hand gestures. The integration of OpenCV enables real-time hand tracking and gesture recognition, providing a seamless and interactive method for controlling volume meters. Explore this Python-based project to experience an intuitive and gesture-driven approach to managing device audio levels, showcasing the versatility and user-friendly nature of hand-based controls."
          subtitle2="Python | OpenCV | ComputerVision"
          videosrc={"https://youtube.com/embed/-pegHhLSizM"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/volumeControlGestureInterface"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
