// SpacingBlock.jsx
import React from "react";

const SpacingBlock = ({ height }) => {
  return (
    <div
      style={{
        height: `${height}px`,
        // Media query for increased margin on mobile
        "@media (max-width: 768px)": {
          marginBottom: "10px", // Increased margin on mobile
        },
      }}
    />
  );
};

export default SpacingBlock;
