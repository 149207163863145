import React from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";

import { Nav, footerRow2 } from "../Components/FooterElements";
import Colors from "../Constants/Colors";

const Footer = () => {
  const socialIconStyle = {
    fontSize: 24,
    color: Colors.primary,
    hoverColor: Colors.secondary,
    marginRight: 30, // Adjust the marginRight value to control the spacing
  };

  const footerContainerStyle = {
    display: "flex",
    flexDirection: "column", // Align items in a column
    alignItems: "center", // Center items horizontally
    marginBottom: 15,
  };

  const footerText = {
    fontSize: 17,
    fontFamily: "Ubuntu",
    color: Colors.primary,
    hoverColor: Colors.secondary,
    marginRight: 25,
  };

  return (
    <div style={footerContainerStyle}>
      <Nav>
        <a
          href="https://github.com/akashhdev"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithub style={socialIconStyle} />
        </a>
        <a
          href="https://www.linkedin.com/in/akashhdev"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin style={socialIconStyle} />
        </a>
        <a
          href="https://twitter.com/akashhdev"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter style={socialIconStyle} />
        </a>
      </Nav>
      <Nav>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <h1 style={footerText}>© 2023 Akash Raj Patel</h1>
        </a>
      </Nav>
    </div>
  );
};

export default Footer;
