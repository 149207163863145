import React, { useState, useEffect } from "react";
import hackathonImg from "../images/Awards/hackathonAward1.jpg";
import productPrototypeImg from "../images/Awards/projectPrototype.jpg";
import styles from "../index.css";
import SpacingBlock from "../CommonBlocks/SpacingBlock";
import { FaTrophy } from "react-icons/fa";
import ScholarshipImg from "../images/Awards/scholarshipImage.png";
import CommonBlockRowRight from "../CommonBlocks/CommonBlockRowRight";

const Awards = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set isVisible to true when the component mounts
    setIsVisible(true);
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <div className={`slide-in ${isVisible ? "visible" : ""}`}>
        <div className="d-flex justify-content-center align-items-center mb-2">
          <FaTrophy style={{ fontSize: "73px", color: "#618EF6" }} />
        </div>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            color: "#65617d",
            marginTop: 10,
            marginBottom: 30,
            fontSize: 35,
          }}
        >
          Awards
        </h1>
      </div>
      <SpacingBlock height={isMobile ? 100 : 0} />
      {/* Use SpacingBlock to control spacing */}

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowRight
          title="1st Rank, Product Prototype Exhibition"
          subtitle="Achieved 1st rank in Product Prototype Exhibition and a cash prize of 10,000 INR (100 USD), hosted at amity university Mumbai. Designed an elegant interface for ordering food and beverages from a standalone screen without touching it using hand gestures."
          subtitle2="Awarded By Amity University Mumbai, India. February 2024"
          imgsrc={productPrototypeImg}
          buttonTitle="Press Release"
          buttonLink="https://www.linkedin.com/feed/update/urn:li:activity:7167847342946164737/"
        />
      </div>

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowRight
          title="2nd Rank, NIRMAN Hackathon"
          subtitle="Achieved 2nd Rank In NIRMAN 2023 and a cash prize of 30,000 INR (450 USD), 48 Hours Hackathon hosted at amity university Mumbai. Designed A python flask API to convert a passed 2D ArcGIS.shp file into a 3D model And render it on a webApp."
          subtitle2="Awarded By Amity University Mumbai, India. February 2023"
          imgsrc={hackathonImg}
          buttonTitle="Press Release"
          buttonLink="https://www.linkedin.com/feed/update/urn:li:activity:7033085922879385600?"
        />
      </div>

      <SpacingBlock height={isMobile ? 340 : 75} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowRight
          title="Receiver Of Dr. Ashok K. Chauhan 100% Scholarship"
          subtitle="Was Awarded Dr.Ashok K. Chauhan 100% Merit Scholarship for the tuition of my 4 year Bachelors Program at Amity University based on my Class 12th Board results. Maintained the Scholarship throughout my program by consistently ranking in top 3 of my program and maintaining a high GPA."
          subtitle2="Awarded By Amity University, India. September 2021"
          imgsrc={ScholarshipImg}
          buttonTitle="Scholar Award Letter"
          buttonLink="https://drive.google.com/file/d/1JeMTj8qFdq-6vuwWyd48afkD61hjtSTX/view?usp=sharing"
        />
      </div>

      <SpacingBlock height={isMobile ? 240 : 90} />
    </>
  );
};

export default Awards;
