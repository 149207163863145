import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import PrimaryButton from "../../CommonBlocks/Buttons/PrimaryButton";
import SpacingBlock from "../../CommonBlocks/SpacingBlock";
import { FaEnvelope } from "react-icons/fa";
import "./RecommendationBlock.css";
import styles from "../../index.css";

const RecommendationBlock = (props) => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  useEffect(() => {
    // Set isVisible to true when the component mounts
    setIsVisible(true);
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <div className={`slide-in ${isVisible ? "visible" : ""} row`}>
        <div className="d-flex justify-content-center align-items-center mb-2">
          <FaEnvelope style={{ fontSize: "60px", color: "#618EF6" }} />
        </div>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            color: "#65617d",
            marginTop: 10,
            marginBottom: 30,
            fontSize: 35,
          }}
        >
          Recommendation Letter
        </h1>
      </div>

      {props.imgsrc2 && <SpacingBlock height={isMobile ? 280 : 650} />}

      <section
        id="header"
        className="d-flex align-items-center"
        style={{
          marginTop: "50px",
          maxHeight: "550px",
          padding: "5px",
        }}
      >
        <div
          className={`container-fluid fade-slide-in ${
            animated && styles.fadeSlideIn
          }`}
        >
          <div className="column">
            <div className="col-12 col-lg-10 mx-auto">
              <div className={`row align-items-center ${"flex-column"}`}>
                <div className="col-md-12 col-lg-6 order-2 order-md-1 text-center mb-3">
                  <img
                    src={props.imgsrc}
                    className="img-fluid rounded-image shadow-image"
                    alt="CommonBlockRowLeft img"
                    style={{
                      width: "100%", // Make image block fill width on mobile and take half on desktop
                      padding: "13px",
                      marginBottom: "30px",
                    }}
                  />
                </div>
                {props.imgsrc2 && (
                  <div className="col-md-12 col-lg-6 order-2 order-md-1 text-center mb-3">
                    <img
                      src={props.imgsrc2}
                      className="img-fluid rounded-image shadow-image"
                      alt="CommonBlockRowLeft img"
                      style={{
                        width: "100%", // Make image block fill width on mobile and take half on desktop
                        padding: "13px",
                        marginBottom: "30px",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecommendationBlock;
