import React, { useState, useEffect } from "react";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Personal Trainer / Bicep Curl Counter System - OpenCV | Python"
          subtitle="
          Created a straightforward computer vision program using OpenCV in Python that achieves real-time tracking of individual bicep curls. This program goes beyond basic tracking by distinguishing between different stages of the curling motion and providing valuable feedback. The integration of OpenCV allows for seamless and efficient processing of video data, enabling the identification and tracking of bicep curls in real-time. This application is designed to enhance workout monitoring by providing insights and feedback on the various stages of the exercise."
          subtitle2="Python | OpenCV | ComputerVision"
          videosrc={"https://youtube.com/embed/8E8YpJiOZkw"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/personalTrainer_OpenCV"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
