import React, { useState, useEffect } from "react";
import projectDemo from "./projectDemoMov.mp4";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Gesture Based Calculator Control Interface - OpenCV | Python"
          subtitle="Developed a gesture-based calculator interface using Python, allowing users to perform arithmetic operations through intuitive gestures and receive real-time display of the calculations. This innovative project utilizes gesture recognition technology to interpret user hand movements and translates them into mathematical operations. The real-time feedback enhances the user experience, providing immediate results as the gestures are executed. This gesture-based calculator serves as an interactive and user-friendly alternative to traditional input methods, offering a dynamic and engaging approach to arithmetic calculations."
          subtitle2="Python | OpenCV | ComputerVision"
          videosrc={"https://youtube.com/embed/e93IjdxkyWk"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/VirtualCalculatorOpenCV"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
