import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Colors from "../Constants/Colors";
import styled from "styled-components";
import styles from "../index.css";
import PrimaryButton from "./Buttons/PrimaryButton";

const TitleCard = (props) => {
  const cardTitle = {
    FontFamily: "Ubuntu",
    color: Colors.secondary,
    fontWeight: "Bold",
    textAlign: "center",
    marginBottom: 20,
  };

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  const cardBox = {
    borderRadius: 30,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.12,
    shadowRadius: 60,
  };

  const cardSubtitle = {
    FontFamily: "Ubuntu",
    color: Colors.secondary,
    textAlign: "center",
  };

  const cardButton = {};

  return (
    <>
      <div
        className={`col-md-4 col-10 mx-auto fade-slide-in  ${
          animated && styles.fadeSlideIn
        }`}
      >
        <div class="card" style={cardBox}>
          <img
            class="card-img-top mx-auto"
            style={{ maxHeight: 350, maxWidth: 350 }}
            src={props.imgsrc}
          />
          <div class="card-body">
            <h5 class="card-title" style={cardTitle}>
              {props.title}
            </h5>
            <p class="card-text" style={cardSubtitle}>
              {props.subtitle}
            </p>
            <PrimaryButton title={props.btName2} to={props.btVisit2} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TitleCard;
