import React, { useState, useEffect } from "react";
import {
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaLinkedin,
  FaGithub,
} from "react-icons/fa";
import "./ContactElementsRow.css"; // Import your CSS file
import styles from "../index.css";

const ContactIconsRow = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);
  return (
    <div
      className={`fade-slide-in contact-icons-row row ${
        animated && styles.fadeSlideIn
      }`}
    >
      <div className="col-6 col-md-2">
        <FaEnvelope />
        <span className="d-none d-md-inline" style={{ marginLeft: "5px" }}>
          hello@akashh.dev
        </span>
      </div>
      <div className="col-6 col-md-2">
        <FaPhone />
        <span className="d-none d-md-inline" style={{ marginLeft: "5px" }}>
          7000568177
        </span>
      </div>
      <div className="col-6 col-md-2">
        <FaMapMarkerAlt />
        <span className="d-none d-md-inline" style={{ marginLeft: "0px" }}>
          Mumbai, India
        </span>
      </div>
      <div className="col-6 col-md-2">
        <FaLinkedin />
        <span
          className="d-none d-md-inline"
          style={{ marginLeft: "5px", fontSize: "16px" }}
        >
          linkedin.com/in/akashhdev
        </span>
      </div>
      <div className="col-6 col-md-2">
        <FaGithub />
        <span className="d-none d-md-inline" style={{ marginLeft: "5px" }}>
          github.com/akashhdev
        </span>
      </div>
    </div>
  );
};

export default ContactIconsRow;
