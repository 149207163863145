import React, { useState, useEffect } from "react";
import projectDemo from "./projectDemoMov.mp4";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Gesture Keyboard Typing Interface - OpenCV | Python"
          subtitle="Created a straightforward virtual keyboard interface displayed on the computer screen, controllable through hand gestures. This application offers a practical solution for situations where a physical keyboard is unavailable. Leveraging computer vision technology, the system tracks hand gestures, allowing users to interact with the virtual keyboard by hovering over and selecting keys. This hands-free virtual keyboard proves useful in scenarios where traditional input methods are not accessible, providing an alternative means for users to input text and control their computer interface."
          subtitle2="Python | OpenCV | ComputerVision"
          videosrc={"https://youtube.com/embed/eI2WCGRazD0?feature=share"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/VirtualKeyboardOpenCV"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
