import React, { useState, useEffect } from "react";
import { FaBriefcase } from "react-icons/fa";
import "./WorkExperienceBlock.css";
import styles from "../index.css";

const WorkExperienceBlock = (props) => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <section
        id="header"
        className="d-flex align-items-center"
        style={{
          marginTop: "50px",
          maxHeight: "550px",
          marginLeft: !isMobile ? "50px" : "0px",
        }}
      >
        <div
          className={` container-fluid fade-slide-in ${
            animated && styles.fadeSlideIn
          }`}
        >
          <div className="row">
            <div className="col-12 col-lg-10 mx-auto">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-7 order-1 order-md-2 text-center mb-3">
                  <img
                    src={props.imgsrc2}
                    className={
                      isMobile
                        ? "img-fluid rounded-image shadow-image"
                        : "img-fluid rounded-image shadow-image animated"
                    }
                    alt="CommonBlockRowLeft img"
                    style={{
                      width: isMobile ? "80%" : "75%", // Make image block fill width on mobile
                      padding: "5px",
                      marginBottom: isMobile ? "0px" : "100px",
                    }}
                  />
                </div>
                <div className="col-md-12 col-lg-5 order-2 order-md-1">
                  <div className="d-flex justify-content-center flex-column">
                    <img
                      src={props.imgsrc}
                      className="img-fluid"
                      alt="CommonBlockRowLeft img"
                      style={{
                        width: "100%",
                        marginTop: isMobile ? "50px" : "0px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkExperienceBlock;
