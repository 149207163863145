import React, { useState, useEffect } from "react";
import RecommendationImg from "../../images/Recommendation/recommendationSatheeshSir.png";
import SpacingBlock from "../../CommonBlocks/SpacingBlock";
import RecommendationBlock from "./RecommendationBlock";
import styles from "../../index.css";

const Resume = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  return (
    <>
      <SpacingBlock height={isMobile ? 20 : 200} />
      <RecommendationBlock title="Recommendation" imgsrc={RecommendationImg} />
      <SpacingBlock height={isMobile ? 110 : 400} />
    </>
  );
};

export default Resume;
