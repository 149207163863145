import React, { useState, useEffect } from "react";
import projectDemo from "./projectDemoMov.mp4";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Real Time Object Detection & Recognition - OpenCV | Python"
          subtitle="Developed a real-time object detection and recognition system using Python and OpenCV. This system is designed to identify and recognize multiple objects from the COCO dataset simultaneously. Leveraging the capabilities of OpenCV, this application provides robust and efficient real-time processing, making it suitable for diverse object recognition scenarios. Explore the functionalities of this system to witness its prowess in simultaneously detecting and recognizing various objects from the COCO dataset, showcasing the potential for applications in diverse fields."
          subtitle2="Python | OpenCV | ComputerVision"
          videosrc={"https://youtube.com/embed/pa_0tHsT2VY"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/ObjectClassification_YOLO"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
