import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DataScienceImg from "../images/Projects/DataScience.png";
import MachingLearningImg from "../images/Projects/MachineLearning.svg";
import TextMingingImg from "../images/Projects/TextMining.png";
import ComputerVisionImg from "../images/Projects/ComputerVision.svg";
import APIImg from "../images/Projects/API.svg";
import ArduinoImg from "../images/Work/Arduino.png";
import TitleCard from "../CommonBlocks/TitleCard";
import { FaCode } from "react-icons/fa";
import SpacingBlock from "../CommonBlocks/SpacingBlock";

const sDataCV = [
  {
    title: "Real Time Face Detection & Blurring OpenCV | Python",
    subtitle:
      "A real time face detection and blurring model using OpenCV in python. (Useful For Blurring faces of strangers in a video)",
    tag: "ComputerVision",
    imgsrc: ComputerVisionImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/factors-affecting-the-housing-market-of-1970s-boston/",
    btVisit2: "/RealTimeFaceBlur_09",
  },
  {
    title: "Coin Classification And Counter - OpenCV | Python",
    subtitle:
      "Python openCV project to detect, differentiate, and calculate the total sum of various coins fed into it via a camera.",
    tag: "ComputerVision",
    imgsrc: ComputerVisionImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/hypothesis-testing-effect-of-recession-on-housing-prices/",
    btVisit2: "/CoinClassification_08",
  },
  {
    title: "Gesture Cursor Control Interface - OpenCV | Python",
    subtitle:
      " virtual mouse that lets users interact with their computer using their hands only (useful when you don't have a mouse on hand).",
    tag: "ComputerVision",
    imgsrc: ComputerVisionImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit: "https://quib.dev/9-handwritten-digit-recognition/",
    btVisit2: "/GestureCursorControl_07",
  },
  {
    title: "Gesture Keyboard Typing Interface - OpenCV | Python",
    subtitle:
      " A simple virtual keyboard interface on a computer screen that can be controlled using hand gestures. (useful when you don't have a one on hand ).",
    tag: "ComputerVision",
    imgsrc: ComputerVisionImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit: "https://quib.dev/9-handwritten-digit-recognition/",
    btVisit2: "/GestureKeyboardControl_06",
  },
  {
    title: "Real Time Object Detection & Recognition  - OpenCV | Python",
    subtitle:
      " A real time object detection and recognition system made using openCV and python. Can detect multiple COCO objects simultaneously. Seriously check it out.",
    tag: "ComputerVision",
    imgsrc: ComputerVisionImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit: "https://quib.dev/9-handwritten-digit-recognition/",
    btVisit2: "/RealTimeObjectDetection_05",
  },
  {
    title: "Personal Trainer / Bicep Curl Counter System  - OpenCV | Python",
    subtitle:
      " A simple computer vision program that can track individual bicep curls in real time as well as differentiate between the stages of the curls and give feedback. Made using openCV in python.      ",
    tag: "ComputerVision",
    imgsrc: ComputerVisionImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit: "https://quib.dev/9-handwritten-digit-recognition/",
    btVisit2: "/BicepCurlCounter_04",
  },
  {
    title: "Gesture Based Coffee Machine Interface - OpenCV | Python",
    subtitle:
      "An entirely virtual coffee machine interface used to place coffee orders A reciept of order confirmation is also printed at the time of order.",
    tag: "ComputerVision",
    imgsrc: ComputerVisionImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/binary-classification-prediction-on-breast-cancer-datasets/",
    btVisit2: "/GestureCoffeMachine_03",
  },
  {
    title: "Gesture Based Calculator Control Interface - OpenCV | Python",
    subtitle:
      "An gesture based calculator interface used to calculate arithmetic operations and display them to the user in real time.",
    tag: "ComputerVision",
    imgsrc: ComputerVisionImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/binary-classification-prediction-on-breast-cancer-datasets/",
    btVisit2: "/GestureBasedCalculator_02",
  },
  {
    title: "2D ArcGIS.Shp Files to 3D Models API | Python",
    subtitle:
      "Python flask API to convert a passed 2D ArcGIS.shp file into a 3D model And render it on a webApp. - Winner of 2nd prize in NIRMAN 2023 Hackathon.",
    tag: "APIs",
    imgsrc: APIImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit: "https://quib.dev/titanic-survival-model/",
    btVisit2: "/ArcGisFilesTo3D_01",
  },
  {
    title: "Gesture Based Volume Control Interface - OpenCV | Python",
    subtitle:
      "An intuitive way of controlling volume meters on a device using hand gestures. Created on python using openCV.",
    tag: "ComputerVision",
    imgsrc: ComputerVisionImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit: "https://quib.dev/titanic-survival-model/",
    btVisit2: "/GestureVolumeControl_10",
  },
];

const sDataDS = [
  {
    title: "Factors Affecting The Housing Market Of 1970’S Boston",
    subtitle:
      "This post is from a two-part series where I dwell deep into the housing prices of Boston – from the Boston Housing Dataset. (Courtesy U.S Census Service and Dr.Jason)",
    tag: "DataScience",
    imgsrc: DataScienceImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/factors-affecting-the-housing-market-of-1970s-boston/",
    btVisit2: "https://akassh.dev/projects",
  },
  {
    title: "Hypothesis Testing: Effect Of Recession On Housing Prices",
    subtitle:
      "The following is a part of my DataScience Project log. Every year more and more high school graduates in the United States elect for tertiary education, some by interest...",
    tag: "DataScience",
    imgsrc: DataScienceImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/hypothesis-testing-effect-of-recession-on-housing-prices/",
    btVisit2: "https://akassh.dev/projects",
  },
  {
    title: "HandWritten Digit Recognition",
    subtitle:
      "I was in sort of a rut in the past few months, I was trying to work on projects that were clearly above my existing skillset. It took some time before I realized to cut my losses and work again on simpler and shorter projects.",
    tag: "MachineLearning",
    imgsrc: MachingLearningImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit: "https://quib.dev/9-handwritten-digit-recognition/",
    btVisit2: "https://akassh.dev/projects",
  },
  {
    title: "Titanic Survival Model",
    subtitle:
      "In this assignment, I go over a labeled dataset containing a list of passengers on board the ship Titanic, – A large civilian cruise vessel that met its unfortunate demise near the Antarctic – and a list of survivals...",
    tag: "MachineLearning",
    imgsrc: MachingLearningImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit: "https://quib.dev/titanic-survival-model/",
    btVisit2: "https://akassh.dev/projects",
  },
  {
    title: "Binary Classification: Prediction On Breast Cancer Datasets",
    subtitle:
      "This time around, I decided to go on a solo machine learning adventure! – That is, I choose the data and goal for myself from the myriad machine learning challenges available on the web.",
    tag: "MachineLearning",
    imgsrc: MachingLearningImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/binary-classification-prediction-on-breast-cancer-datasets/",
    btVisit2: "https://akassh.dev/projects",
  },
  {
    title:
      "Machine Learning: Predicting Probabilities Of Blight Ticket Compliance",
    subtitle:
      "The following is a part of my machine learning project log completed during the applied machine learning introduction course from Coursera.",
    tag: "MachineLearning",
    imgsrc: MachingLearningImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/neural-networks-predicting-probabilities-of-blight-ticket-compliance/",
    btVisit2: "https://akassh.dev/projects",
  },
  {
    title: "Multinomial Naive Bayes Classifier: Predicting Spams",
    subtitle:
      "In this assignment, I was given a labeled dataset containing spam and not-spam text messages.",
    tag: "TextMining",
    imgsrc: TextMingingImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/multinomial-naive-bayes-classifier-predicting-spams/",
    btVisit2: "https://akassh.dev/projects",
  },
  {
    title: "Feature Extraction: Dates From Data",
    subtitle:
      "The following is a part of my text mining project log completed during the applied text mining in python course from Coursera.",
    tag: "TextMining",
    imgsrc: TextMingingImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit: "https://quib.dev/feature-extraction-dates-from-data/",
    btVisit2: "https://akassh.dev/projects",
  },
  {
    title: "Developing An Ultrasonic Depth Sensor Gauge In Arduino",
    subtitle:
      "This post was a result of the skills I obtained from the Arduino Beginner’s Course by...",
    tag: "Arduino",
    imgsrc: ArduinoImg,
    btName: "Github Repo",
    btName2: "Project Demo",
    btVisit:
      "https://quib.dev/developing-an-ultrasonic-depth-sensor-gauge-in-arduino/",
    btVisit2: "https://akassh.dev/projects",
  },
];

const ButtonPrimary = styled.button`
  background-color: #618ef6;
  font: 700 26px Ubuntu;
  color: white;
  padding: 15px 25px;
  margin: 10px;
  border-radius: 30px;
  border-width: 0px;
  outline-color: #77a0ff;
  text-transform: uppercase;
  box-shadow: 0px 4px 4px lightgray;
  cursor: pointer;
  transition: ease background-color 300ms;
  &:hover {
    background-color: #77a0ff;
  }
`;

const Projects = () => {
  const aboutCertificateBlock = {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 20,
  };
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px
  const [showAll, setShowAll] = useState(false);

  const visibleData = showAll ? sDataCV : sDataCV.slice(0, 3);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set isVisible to true when the component mounts
    setIsVisible(true);
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <SpacingBlock height={isMobile ? 25 : 0} />
      <div className={`slide-in ${isVisible ? "visible" : ""} row`}>
        <div className="d-flex justify-content-center align-items-center mb-2">
          <FaCode style={{ fontSize: "60px", color: "#618EF6" }} />
        </div>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            color: "#65617d",
            marginTop: 10,
            marginBottom: 30,
            fontSize: 35,
          }}
        >
          Personal Projects
        </h1>
      </div>
      <div className="container-fluid mb-5">
        <div style={{ marginBottom: 100, marginTop: 10 }} className="row">
          <div className="col-10 mx-auto">
            <div className="row gy-5">
              {visibleData.map((val, ind) => (
                <TitleCard
                  key={ind}
                  title={val.title}
                  subtitle={val.subtitle}
                  imgsrc={val.imgsrc}
                  btName={val.btName}
                  btName2={val.btName2}
                  btVisit={val.btVisit}
                  btVisit2={val.btVisit2}
                />
              ))}
            </div>
            {sDataCV.length > 3 && !showAll && (
              <div style={{ textAlign: "center" }}>
                <ButtonPrimary
                  onClick={() => setShowAll(true)}
                  style={{ marginTop: 50 }}
                >
                  View All
                </ButtonPrimary>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
