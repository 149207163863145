import React, { useState, useEffect } from "react";
import introImg from "../images/HomePage/homepage_pfp.png";
import CommonBlockRowLeft from "../CommonBlocks/CommonBlockRowLeft";
import ContactElementsRow from "../Components/ContactElementsRow";
import SpacingBlock from "../CommonBlocks/SpacingBlock";
import PrimaryButton from "../CommonBlocks/Buttons/PrimaryButton";
import Education from "./Education";
import Work from "./Work";
import Projects from "./Projects";
import Resume from "./Resume";
import styles from "../index.css";
import Awards from "./Awards";

const Home = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 100 : 0} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Hi, I’m Akash. I Turn Data Into Insights."
          subtitle="A Machine learning engineer with a passion for turning raw data into meaningful insights. From leading diverse teams across Asia to winning high-paced hackathons, I thrive on transforming ideas into impactful solutions. Fluent in English, aspiring to bring my skills to your organization."
          imgsrc={introImg}
          buttonTitle="View Resume"
          buttonLink="/resume"
        />
      </div>

      <SpacingBlock height={isMobile ? 0 : 50} />

      {!isMobile && (
        <ContactElementsRow
          className={`fade-slide-in ${animated && styles.fadeSlideIn}`}
        />
      )}
      <SpacingBlock height={isMobile ? 175 : 70} />

      <SpacingBlock height={isMobile ? 0 : 120} />

      {isMobile && (
        <>
          <Education
            className={`fade-slide-in ${animated && styles.fadeSlideIn}`}
          />
          <SpacingBlock height={170} />

          <Work className={`fade-slide-in ${animated && styles.fadeSlideIn}`} />

          <SpacingBlock height={40} />

          <Awards
            className={`fade-slide-in ${animated && styles.fadeSlideIn}`}
          />

          <Projects
            className={`fade-slide-in ${animated && styles.fadeSlideIn}`}
          />
          <Resume
            className={`fade-slide-in ${animated && styles.fadeSlideIn}`}
          />
        </>
      )}

      {/*
<InterestsBlock
  title="Skills"
  imgsrc={interestsImg}
  visit="#"
  btName1="Data Science"
  btName2="Machine Learning"
  btName3="Text Mining"
  btName4="Arduino"
  btVisit1="/work"
  btVisit2="/work"
  btVisit3="/work"
  btVisit4="/work"
/>
*/}
      {/*
<ContactMeBlock
  title="Got Questions?"
  imgsrc={contactImg}
  btName1="Contact Me"
  btVisit1="/contact"
  btName2="About Page"
  btVisit2="/about"
/>
*/}
    </>
  );
};

export default Home;
