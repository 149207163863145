import React from "react";
//import { NavLink } from "react-router-dom";

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  navHeader,
  NavBtn,
  NavBtnLink,
} from "../../src/Components/NavbarElements";

const Navbar = () => {
  const navLinkInactive = {
    fontSize: 26,
    fontFamily: "Ubuntu",
    color: "#65617d",
    hoverColor: "#383549",
  };

  const navLinkActive = {
    fontSize: 26,
    fontFamily: "Ubuntu",
    color: "#618EF6",
  };

  const navbtnText = {
    fontSize: 26,
    fontFamily: "Ubuntu",
    fontWeight: "bold",
    color: "white",
  };

  return (
    <>
      <Nav style={{ Width: "1200px", margin: "0 auto" }}>
        <Bars />
        <NavLink style={{ flex: 1 }} exact to="/">
          <h1
            style={{
              fontSize: 36,
              fontWeight: "bold",
              fontStyle: "oblique",
              color: "#817e94",
              marginRight: 5,
            }}
          >
            //
          </h1>
          <h1
            style={{
              fontSize: 36,
              fontWeight: "bold",
              fontStyle: "oblique",
              color: "#65617d",
            }}
          >
            Akas
          </h1>
          <h1
            style={{
              fontSize: 36,
              fontWeight: "bold",
              fontStyle: "oblique",
              color: "#618EF6",
            }}
          >
            hh
          </h1>
        </NavLink>
        <NavMenu>
          <NavLink to="/education" activeStyle={navLinkActive}>
            <h1 style={navLinkInactive}>Education</h1>
          </NavLink>
          <NavLink to="/work" activeStyle={navLinkActive}>
            <h1 style={navLinkInactive}>Experience</h1>
          </NavLink>
          <NavLink to="/awards" activeStyle={navLinkActive}>
            <h1 style={navLinkInactive}>Awards</h1>
          </NavLink>
          <NavLink to="/projects" activeStyle={navLinkActive}>
            <h1 style={navLinkInactive}>Projects</h1>
          </NavLink>
          <NavLink to="/resume" activeStyle={navLinkActive}>
            <h1 style={navLinkInactive}>Resume</h1>
          </NavLink>
          <NavBtn style={{ color: "#618EF6" }}>
            <NavBtnLink to="/contact">
              <h1 style={navbtnText}>Contact</h1>
            </NavBtnLink>
          </NavBtn>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
