import React, { useState, useEffect } from "react";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="2D ArcGIS.Shp Files to 3D Models API | Python"
          subtitle="
          Develop a Python Flask API that transforms a provided 2D ArcGIS.shp file into a 3D model and seamlessly renders it on a web application. This project, recognized as the 2nd prize winner in the NIRMAN 2023 Hackathon, showcases a novel approach to spatial data manipulation. The API facilitates the conversion of ArcGIS.shp files, traditionally 2D, into dynamic 3D models, expanding the possibilities for enhanced visualization and interaction on web platforms."
          subtitle2="Python | Flask | API"
          videosrc={"https://youtube.com/embed/m7ln6uTDEbw"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/nirmanHackathon2023RunnerUp"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
