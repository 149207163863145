import React, { useState, useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import certificates from "./Pages/Certificates";
import Contact from "./Pages/Contact";
import Navbar from "./Pages/Navbar";
import Footer from "./Pages/Footer";
import Work from "./Pages/Work";
import Awards from "./Pages/Awards";
import Education from "./Pages/Education";
import Projects from "./Pages/Projects";
import Resume from "./Pages/Resume";
import MailSent from "./Pages/Mail-sent-success";
import "@fortawesome/fontawesome-svg-core/styles.css";

//project demo pages
import RealTimeFaceBlur_09 from "./Pages/ProjectDemos/RealTimeFaceBlur_09/projectDemo";
import CoinClassification_08 from "./Pages/ProjectDemos/CoinClassification_08/projectDemo";
import GestureCursorControl_07 from "./Pages/ProjectDemos/GestureCursorControl_07/projectDemo";
import GestureKeyboardControl_06 from "./Pages/ProjectDemos/GestureKeyboardControl_06/projectDemo";
import RealTimeObjectDetection_05 from "./Pages/ProjectDemos/RealTimeObjectDetection_05/projectDemo";
import ArcGisFilesTo3D_01 from "./Pages/ProjectDemos/2DArcGisFilesTo3D_01/projectDemo";
import BicepCurlCounter_04 from "./Pages/ProjectDemos/BicepCurlCounter_04/projectDemo";
import GestureCoffeMachine_03 from "./Pages/ProjectDemos/GestureCoffeMachine_03/projectDemo";
import GestureBasedCalculator_02 from "./Pages/ProjectDemos/GestureBasedCalculator_02/projectDemo";
import GestureVolumeControl_10 from "./Pages/ProjectDemos/GestureVolumeControl_10/projectDemo";

//recommendation letter pages
import recommendationSatheeshSir from "./Pages/Recommendation/SatheeshSir";
import recommendationPaoAnnHsiungSir from "./Pages/Recommendation/PaoAnnHsiungSir";

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 844);

  useEffect(() => {
    const handleResize = () => {
      const mobileCondition = window.innerWidth <= 844;
      if (mobileCondition !== isMobile) {
        setIsMobile(mobileCondition);
        window.location.reload();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <>
      <Navbar />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Home {...props} isMobile={isMobile} />}
        />
        <Route exact path="/about" component={About} />
        <Route exact path="/certificates" component={certificates} />
        <Route
          exact
          path="/education"
          render={(props) => <Education {...props} isMobile={isMobile} />}
        />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/work" component={Work} />
        <Route exact path="/awards" component={Awards} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/resume" component={Resume} />
        <Route exact path="/success" component={MailSent} />
        <Route
          exact
          path="/ArcGisFilesTo3D_01"
          component={ArcGisFilesTo3D_01}
        />
        <Route
          exact
          path="/BicepCurlCounter_04"
          component={BicepCurlCounter_04}
        />
        <Route
          exact
          path="/CoinClassification_08"
          component={CoinClassification_08}
        />
        <Route
          exact
          path="/GestureBasedCalculator_02"
          component={GestureBasedCalculator_02}
        />
        <Route
          exact
          path="/GestureCoffeMachine_03"
          component={GestureCoffeMachine_03}
        />
        <Route
          exact
          path="/GestureCursorControl_07"
          component={GestureCursorControl_07}
        />
        <Route
          exact
          path="/GestureKeyboardControl_06"
          component={GestureKeyboardControl_06}
        />
        <Route
          exact
          path="/GestureVolumeControl_10"
          component={GestureVolumeControl_10}
        />
        <Route
          exact
          path="/realTimeFaceBlur_09"
          component={RealTimeFaceBlur_09}
        />
        <Route
          exact
          path="/RealTimeObjectDetection_05"
          component={RealTimeObjectDetection_05}
        />
        <Route
          exact
          path="/recommendationSatheeshSir"
          component={recommendationSatheeshSir}
        />
        <Route
          exact
          path="/recommendationPaoAnnHsiungSir"
          component={recommendationPaoAnnHsiungSir}
        />

        <Redirect to="/" />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
