import React, { useState, useEffect } from "react";
import WorkExpImg from "../images/Work/CCUExpImg.svg";
import WorkGroupPhoto from "../images/Work/CCUGroupPhoto.png";
import styles from "../index.css";
import SpacingBlock from "../CommonBlocks/SpacingBlock";
import WorkExperienceBlock from "../CommonBlocks/WorkExperienceBlock";
import { FaBriefcase } from "react-icons/fa";
import PaoAnnHsiungImg from "../images/Work/Pao-annHsiung-pfp.png";
import CommonBlockRowLeft from "../CommonBlocks/CommonBlockRowLeft";

const Work = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  useEffect(() => {
    // Set isVisible to true when the component mounts
    setIsVisible(true);
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <div className={`slide-in ${isVisible ? "visible" : ""} row`}>
        <div className="d-flex justify-content-center align-items-center mb-2">
          <FaBriefcase style={{ fontSize: "60px", color: "#618EF6" }} />
        </div>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            color: "#65617d",
            marginTop: 10,
            marginBottom: 30,
            fontSize: 35,
          }}
        >
          Work Experience
        </h1>
      </div>

      <SpacingBlock height={isMobile ? 60 : 30} />
      {/* Use SpacingBlock to control spacing */}
      <WorkExperienceBlock
        title="Work Experience"
        imgsrc={WorkExpImg}
        imgsrc2={WorkGroupPhoto}
      />

      <SpacingBlock height={isMobile ? 330 : 0} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn} `}>
        <CommonBlockRowLeft
          title='"Akash demonstrated exemplary dedication and leadership during his internship"'
          subtitle="As a sophomore, Akash showcased rare leadership qualities by effectively leading a diverse team of six interns from India and Denmark. He orchestrated collaborative efforts, monitored individual progress, and compiled comprehensive reports, showcasing exceptional capabilities that undoubtedly foreshadow a promising future career..."
          subtitle2="Pao-Ann Hsiung, Ph.D. Director, Research Center on AI and Sustainability National Chung Cheng University, Taiwan"
          imgsrc={PaoAnnHsiungImg}
          buttonTitle="Recommendation Letter"
          buttonLink="/recommendationPaoAnnHsiungSir"
        />
      </div>

      <SpacingBlock height={isMobile ? 290 : 90} />
    </>
  );
};

export default Work;
