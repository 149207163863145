import React, { useState, useEffect } from "react";
import ResumeImg from "../images/Resume/ResumeImg.png";
import SpacingBlock from "../CommonBlocks/SpacingBlock";
import ResumeBlock from "../CommonBlocks/ResumeBlock";
import styles from "../index.css";

const Resume = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  return (
    <>
      <SpacingBlock height={isMobile ? 10 : 0} />
      <ResumeBlock title="Resume" imgsrc={ResumeImg} />
      <SpacingBlock height={isMobile ? 110 : 350} />
    </>
  );
};

export default Resume;
