import React, { useState, useEffect } from "react";
import projectDemo from "./projectDemoMov.mp4";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Gesture Cursor Control Interface - OpenCV | Python"
          subtitle="Developed a virtual mouse application that enables users to interact with their computer solely through hand gestures, providing a practical solution for situations where a physical mouse is not available. This innovative system utilizes computer vision technology to track and interpret users' hand movements, translating them into mouse actions such as pointing, clicking, and scrolling. The virtual mouse eliminates the need for a physical mouse, offering a hands-only approach for navigating and interacting with the computer interface. This solution proves particularly useful in scenarios where traditional input devices are unavailable or impractical."
          subtitle2="Python | OpenCV | ComputerVision"
          videosrc={"https://youtube.com/embed/bQeqt9zLXgM"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/cursorGestureControlInterface"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
