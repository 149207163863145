import React, { useState, useEffect } from "react";
import EducationImg from "../images/Education/EducationImg.svg";
import EducationGroupImg from "../images/Education/EducationImgGroup.png";
import EducationBlock from "../CommonBlocks/EducationBlock";
import styles from "../index.css";
import SpacingBlock from "../CommonBlocks/SpacingBlock";
import { FaGraduationCap } from "react-icons/fa";
import SatheeshSirImg from "../images/Work/SatheeshAM-pfp.png";
import CommonBlockRowLeft from "../CommonBlocks/CommonBlockRowLeft";
import CommonBlockRowRight from "../CommonBlocks/CommonBlockRowRight";

const Education = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set isVisible to true when the component mounts
    setIsVisible(true);
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <div className={`slide-in ${isVisible ? "visible" : ""}`}>
        <div className="d-flex justify-content-center align-items-center mb-2">
          <FaGraduationCap style={{ fontSize: "73px", color: "#618EF6" }} />
        </div>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            color: "#65617d",
            marginTop: 10,
            marginBottom: 30,
            fontSize: 35,
          }}
        >
          Education
        </h1>
      </div>
      <SpacingBlock height={isMobile ? 50 : 80} />
      {/* Use SpacingBlock to control spacing */}
      <EducationBlock
        title="Education"
        subtitle="I have pursued my interest in computer science and artificial intelligence ever since highschool."
        imgsrc={EducationImg}
        imgsrc2={EducationGroupImg}
      />

      <SpacingBlock height={isMobile ? 480 : 220} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title='"Highly intelligent and hardworking student, consistently ranking top of his class."'
          subtitle="His recent achievement of winning the Second Prize, with a reward of 450 USD in a National level Hackathon is particularly impressive. Showcasing exceptional programming skills. Upon reviewing his research blog, it is clear that Akash possesses a strong passion and deep understanding in the fields of AI and Machine Learning. His admirable dedication to his studies suggests that he would excel in any academic or research program he chooses to undertake."
          subtitle2="Satheesh Abimannan
          Professor & Deputy Director
          Department of Computer Science and Engineering
          ASET, Amity University Mumbai, India
          "
          imgsrc={SatheeshSirImg}
          buttonTitle="Recommendation Letter"
          buttonLink="/recommendationSatheeshSir"
        />
      </div>

      <SpacingBlock height={isMobile ? 240 : 90} />
    </>
  );
};

export default Education;
