import React, { useState, useEffect } from "react";
import projectDemo from "./projectDemoMov.mp4";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Gesture Based Coffee Machine Interface - OpenCV | Python"
          subtitle="Created a fully virtual coffee machine interface that enables users to place coffee orders seamlessly. This virtual interface emulates the experience of interacting with a physical coffee machine, allowing users to select their preferred coffee type and customize their order. Upon confirmation, the system generates and prints a receipt, providing users with an immediate record of their order details. This virtual coffee machine interface offers a convenient and efficient way for users to place orders and receive order confirmations, enhancing the virtual coffee ordering experience."
          subtitle2="Python | OpenCV | ComputerVision"
          videosrc={"https://youtube.com/embed/yz24zvuXmSY"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/VirtualCoffeeMachineInterface_openCV"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
