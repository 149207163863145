import React, { useState, useEffect } from "react";
import projectDemo from "./projectDemoMov.mov";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Real Time Face Detection & Blurring OpenCV"
          subtitle="Develop a real-time face detection and blurring model using OpenCV in Python, designed specifically for the purpose of enhancing privacy in videos. This model proves particularly useful in scenarios where there's a need to automatically detect and blur the faces of unknown individuals within a video stream, ensuring the protection of their privacy. The implementation involves leveraging OpenCV's capabilities to seamlessly identify faces in real-time and apply an effective blurring mechanism, contributing to a broader spectrum of applications aimed at safeguarding personal information in multimedia content"
          subtitle2="Python | OpenCV | ComputerVision"
          videosrc={"https://youtube.com/embed/KobhezULv7I"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/faceBlurApp"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
