import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const ButtonPrimary = styled.button`
  background-color: #618ef6;
  font: 700 26px Ubuntu;
  color: white;
  padding: 15px 25px;
  margin: 10px;
  border-radius: 30px;
  border-width: 0px;
  outline-color: #77a0ff;
  text-transform: uppercase;
  box-shadow: 0px 4px 4px lightgray;
  cursor: pointer;
  transition: ease background-color 300ms;
  &:hover {
    background-color: #77a0ff;
  }
`;

const CenteredButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 400px; /* Adjust the max height as needed */
  margin: 0 auto; /* Center horizontally */
`;

const PrimaryButton = ({ title, to }) => {
  const isExternalLink = /^https?:\/\//.test(to);
  const history = useHistory();

  const handleClick = () => {
    if (isExternalLink) {
      // Handle external link navigation
      window.open(to, "_blank");
    } else {
      // Handle internal link navigation
      history.push(to);
    }
  };

  return (
    <CenteredButton>
      <ButtonPrimary onClick={handleClick}>{title}</ButtonPrimary>
    </CenteredButton>
  );
};

export default PrimaryButton;
