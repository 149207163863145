import React, { useState, useEffect } from "react";
import projectDemo from "./projectDemoMov.mp4";
import CommonBlockRowLeft from "../../../CommonBlocks/CommonBlockRowLeft";
import SpacingBlock from "../../../CommonBlocks/SpacingBlock";
import styles from "../../../index.css";

const Project = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <>
      <SpacingBlock height={isMobile ? 500 : 50} />

      <div className={`fade-slide-in ${animated && styles.fadeSlideIn}`}>
        <CommonBlockRowLeft
          title="Coin Classification And Counter - OpenCV | Python"
          subtitle="Developed a Python project using OpenCV for the real-time detection, differentiation, and total sum calculation of various coins captured by a camera. This application leverages the power of computer vision to accurately identify different coin types, distinguish between them, and dynamically calculate the cumulative value. The integration of OpenCV ensures efficient image processing, enabling the program to provide a comprehensive assessment of the total monetary value based on the coins presented to the camera feed. This project serves as a practical and automated solution for coin recognition and valuation."
          subtitle2="Python | OpenCV | ComputerVision"
          videosrc={"https://youtube.com/embed/b9UDZGF7Hx8"}
          buttonTitle="Github Repository"
          buttonLink="https://github.com/akashhdev/coinCounterUsingOpenCV"
        />
      </div>

      <SpacingBlock height={isMobile ? 450 : 100} />
    </>
  );
};

export default Project;
