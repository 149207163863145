import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { CommonButton } from "./Buttons/CommonButton";
import { Button } from "react-bootstrap";
import styles from "../index.css";
import PrimaryButton from "../CommonBlocks/Buttons/PrimaryButton";
import SpacingBlock from "../CommonBlocks/SpacingBlock";

const CommonBlockRowLeft = (props) => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  const [animated, setAnimated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  useEffect(() => {
    // Set isVisible to true when the component mounts
    setIsVisible(true);
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <section
        id="header"
        className="d-flex align-items-center"
        style={{
          marginTop: "15px",
          maxHeight: "550px",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-10 mx-auto">
              <div className="row align-items-center">
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src={props.imgsrc}
                    className={
                      isMobile
                        ? "img-fluid rounded-image shadow-image"
                        : "img-fluid rounded-image shadow-image animated"
                    }
                    alt="CommonBlockRowRight img"
                    style={{
                      width: isMobile ? "90%" : "80%", // Make image block fill width on mobile
                      padding: "5px",
                    }}
                  />
                </div>
                <div className="col-lg-6 order-1 order-lg-1">
                  <div className="d-flex justify-content-center flex-column">
                    <h1
                      className="text-center my-3"
                      style={{ fontSize: isMobile ? "34px" : undefined }}
                    >
                      {props.title}
                    </h1>
                    <h2
                      className="text-center my-3"
                      style={{ fontSize: isMobile ? "24px" : undefined }}
                    >
                      {props.subtitle}
                    </h2>
                    <h2
                      className="text-center my-3 mx-auto"
                      style={{
                        fontSize: isMobile ? "21px" : "21px",
                        maxWidth: "75%",
                      }}
                    >
                      {props.subtitle2}
                    </h2>
                    <div
                      className={`fade-slide-in ${
                        animated && styles.fadeSlideIn
                      }`}
                    >
                      {props.buttonLink && (
                        <>
                          <SpacingBlock height={isMobile ? 20 : 0} />
                          <PrimaryButton
                            title={props.buttonTitle}
                            to={props.buttonLink}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonBlockRowLeft;
