import React, { useState, useEffect } from "react";
import RecommendationImg1 from "../../images/Recommendation/recommendationPaoAnnHsiungSir1.png";
import RecommendationImg2 from "../../images/Recommendation/recommendationPaoAnnHsiungSir2.png";
import SpacingBlock from "../../CommonBlocks/SpacingBlock";
import RecommendationBlock from "./RecommendationBlock";
import styles from "../../index.css";

const Resume = () => {
  const isMobile = window.innerWidth <= 844; // Assuming mobile resolution is less than or equal to 767px

  return (
    <>
      <SpacingBlock height={isMobile ? 10 : 0} />
      <RecommendationBlock
        title="Recommendation"
        imgsrc={RecommendationImg1}
        imgsrc2={RecommendationImg2}
      />
      <SpacingBlock height={isMobile ? 350 : 700} />
    </>
  );
};

export default Resume;
